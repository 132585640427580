import "../styles/showcase.css"
import "../styles/case-studies.css"

import React from 'react'
import { Link } from 'gatsby'
import Fade from 'react-reveal/Fade';

import ExternalLinkIcon from "../images/external-link.svg"

function Showcase(props) {
  const { reverse, type, title, color, textColor, image, tags, description, laptop, link, caseStudylink} = props;
  return (
    <div className={`${reverse ? 'showcase-container-reverse' : 'showcase-container'}`} style={{ backgroundColor: color, color: `${textColor === 'light' ? '#FAFAFA' : '#535353'}`}}>
      <Fade bottom>
        <div id={type} className={`showcase-image ${ laptop ? 'laptop' : '' }`} style={{ backgroundImage: `url('${image}')`}} />
      </Fade>
      <Fade cascade>
        <div className="showcase-content">
          <div className="showcase-text">
            { link 
                ? <a href={link} target="_blank" rel="noopener noreferrer"><div className="showcase-title">{title}<img className="external-link-icon" src={ExternalLinkIcon} /></div></a>
                : <div className="showcase-title">{title}</div>
            }
            <div className="showcase-tag-container">
              { tags.map(tag => <div className="showcase-tag" style={{ borderColor: `${textColor === 'light' ? '#FAFAFA' : '#535353' }`}}>{tag}</div>) }
            </div>
            { description.map(paragraph => <div className="showcase-description">{paragraph}</div>) }
          </div>
          { caseStudylink 
              ?  
              <div className="case-studies-button-container">
                <Link to={caseStudylink}>
                  <div className="case-studies-button">
                    Read Case Study
                  </div>
                </Link>
              </div>
              : null
          }
        </div>
      </Fade>
    </div>
  )
}

export default Showcase;
